(function(){ 'use strict';


angular.module('app.controllers', ['app.constants'])
  .controller('MainController', MainController);


MainController.$inject = ['$http', '$location', '$timeout', '$translate'];
function MainController($http, $location, $timeout, $translate) {
  this.refCode = null;
  this.$translate = $translate;

  this.init = () => {
    const urlParams = $location.search();
    if (urlParams.c) this.refCode = urlParams.c;
    if (urlParams.ref_code) this.refCode = urlParams.ref_code;
  };

  this.submitRefCode = ($event) => {
    if ($event) $event.preventDefault();
    if (this.submitRefCode.$loading) return;

    this.submitRefCode.$loading = true;
    $http.get('kiosk-info', { params: { refCode: this.refCode } })
      .then(resp => {
        this.submitRefCode.$loading = false;
        const url = addQueryStringParams(resp.data.shippingInfoFormUrl, {
          ref_code: this.refCode,
          hide_ref_code: 1,
        });
        window.location.href = url;
      })
      .catch(resp => {
        this.submitRefCode.$loading = false;
        console.error(resp.data);
        alert(resp.data.message);
      });
  };

  this.submitRefCode.isDataValid = () => (
    Boolean(this.refCode)
  );
}

function addQueryStringParams(uri, params) {
  Object.keys(params).forEach(key => {
    const value = params[key];
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      uri = uri.replace(re, `$1${key}=${value}$2`);
    }
    uri = `${uri}${separator}${key}=${value}`;
  });
  return uri;
}
 })();