(function(){ 'use strict';


angular
  .module('app', [
    'ngTouch',
    'pascalprecht.translate',
    'app.controllers',
    'app.constants',
  ])
  .run(run)
  .config(config);


config.$inject = ['$interpolateProvider', '$locationProvider', '$translateProvider'];
function config($interpolateProvider, $locationProvider, $translateProvider) {
  $locationProvider.html5Mode(true);
  $interpolateProvider.startSymbol('{$');
  $interpolateProvider.endSymbol('$}');

  $translateProvider.useStaticFilesLoader({
    prefix: 'locales/',
    suffix: '.json',
  });
  $translateProvider
    .registerAvailableLanguageKeys(['en', 'zh-Hant', 'ja'], {
      'en*': 'en',
      'zh*': 'zh-Hant',
      'ja*': 'ja',
    })
    .fallbackLanguage('en')
    .determinePreferredLanguage();
}


run.$inject = ['$location', '$translate', '$http'];
function run($location, $translate, $http) {
  const { lang } = $location.search();
  if (lang) $translate.use(lang);

  $http.defaults.headers.common['Accept-Language'] = () => (
    $translate.proposedLanguage() || $translate.use()
  );
}

 })();